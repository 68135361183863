<template>
  <div>
    <div :class="$style.worldSearch">
      <main :class="[$style.main, isComparing && $style.comparing]">
        <div :class="$style.logoContainer">
          <img
            :class="$style.logo"
            src="@/assets/img/logo-sm.png"
            alt="Rightsholders Logo"
          />
        </div>
        <div
          v-show="!isComparing"
          class="main-section"
          style="
            background: url('images/page2-bg.png') no-repeat center center/100%;
          "
        >
          <div class="main-section-inner">
            <form @submit.prevent="onSearch" class="search-type-content">
              <h1 class="h1">
                <span class="highlight">Find your work</span>
                <span class="highlight">registrations</span>
                <span class="highlight">around the world</span>
              </h1>
              <div class="single-input">
                <label for="code">iswc or isrc code</label>
                <input
                  :class="$style.codeInput"
                  v-model="code"
                  type="text"
                  name="code"
                  id="code"
                />
              </div>
              <div class="single-input">
                <div v-if="searchWorkRegistrationsStatusSuccess">
                  <button
                    type="submit"
                    class="button"
                    :class="$style.searchWorkRegistrationsBtn"
                    @click.prevent.stop="resetForm"
                  >
                    Reset
                  </button>
                  <div :class="$style.tagsContainer">
                    <button
                      v-for="(
                        { country_tag, source, occurences }, idx
                      ) of countriesTags"
                      :key="`${source}-${idx}`"
                      :class="[
                        $style.sourceTagBtn,
                        occurences > 1 && $style.multiple,
                      ]"
                      @click.prevent="onSourceClick(country_tag, source)"
                    >
                      {{ source }}
                    </button>
                  </div>
                </div>
                <button
                  :disabled="searchWorkRegistrationsStatusPending"
                  type="submit"
                  class="button"
                  :class="$style.searchWorkRegistrationsBtn"
                  v-else
                >
                  {{
                    searchWorkRegistrationsStatusPending
                      ? "Searching..."
                      : "Search"
                  }}
                </button>
              </div>
              <div
                v-show="!searchWorkRegistrationsStatusSuccess"
                class="single-input result-box"
              >
                <label
                  >Millions of songs. <br />
                  Hundreds of databases. <br />
                  One single search.</label
                >
              </div>
            </form>
            <div class="logo-column ms-auto">
              <a href="#" class="logo">
                <img src="images/Logo.png" alt="" />
              </a>
            </div>
          </div>
        </div>

        <div
          :class="[
            $style.detailsCardContainer,
            isComparing && $style.comparing,
          ]"
        >
          <DetailsCard
            :sources="countryDetails"
            :fetchCountryDataStatus="
              isComparing ? API_STATUS.SUCCESS : fetchCountryDataStatus
            "
            :showCountryDetails="showCountryDetails"
            :selectedSource="selectedSource"
            @close="onCloseCountryDetailsCard"
            @compare="isComparing = !isComparing"
            :isComparing="isComparing"
            isOriginalCard
            :countriesData="countriesData"
          />
        </div>
        <div
          v-if="isComparing"
          :class="[
            $style.detailsCardCompareContainer,
            isComparing && $style.comparing,
          ]"
        >
          <DetailsCard
            :sources="countryDetailsCompare"
            :fetchCountryDataStatus="fetchCountryDataStatus"
            :showCountryDetails="showCountryDetails"
            :selectedSource="selectedSource"
            :isComparing="isComparing"
            :countriesTags="countriesTags"
            :isOriginalCard="false"
            :countriesData="countriesData"
            :selectedCompareCountry="selectedCompareCountry"
            :selectedCompareSource="selectedCompareSource"
            @close="onCloseCountryDetailsCard"
            @compare="isComparing = !isComparing"
            @fetchCountryDetails="onFetchCompareCountryDetails"
            @onChangeCompareSource="onChangeCompareSource"
          />
        </div>
      </main>
    </div>
    <div
      class="countriesSVGContainer"
      ref="countriesSVGContainer"
      :class="$style.countriesSVGContainer"
    >
      <CountriesSVG :class="$style.countriesSVG" ref="countriesSVG" />
    </div>
  </div>
</template>

<script>
import panzoom from "panzoom";
import CountriesSVG from "./countries.svg?inline";
import { API_STATUS } from "@/constants/apiStatus";
import { apiStatusComputedFactory } from "@/api/helpers/apiStatusComputedFactory";
import { withAsync } from "@/helpers";
import {
  searchWorkRegistrations,
  searchWorkRegistrationsByCountry,
} from "@/api/workRegistrationApi";
import DetailsCard from "./DetailsCard";
const { IDLE, PENDING, SUCCESS, ERROR } = API_STATUS;
export default {
  components: {
    CountriesSVG,
    DetailsCard,
  },
  data() {
    return {
      searchWorkRegistrationsStatus: IDLE,
      code: "",
      // iswc: "T9257296290",
      countriesData: {},
      fetchCountryDataStatus: IDLE,
      showCountryDetails: false,
      selectedCountry: null,
      selectedSource: null,
      selectedCompareCountry: null,
      selectedCompareSource: null,
      countries: [],
      isComparing: false,
    };
  },
  created() {
    this.API_STATUS = API_STATUS;
  },
  mounted() {
    this.isMapZoomedIn = false;

    this.mapPanzoom = panzoom(this.$refs.countriesSVG.childNodes[1], {
      bounds: true,
      beforeWheel: () => true,
      beforeMouseDown: () => !this.isMapZoomedIn,
    });
  },
  beforeDestroy() {
    this.mapPanzoom.dispose();
  },
  computed: {
    ...apiStatusComputedFactory("searchWorkRegistrationsStatus"),
    ...apiStatusComputedFactory("fetchCountryDataStatus"),
    countriesTags() {
      let data = {};
      for (const item of this.countries) {
        if (item.source in data) {
          data[item.source].occurences++;
        } else {
          data[item.source] = {
            ...item,
            occurences: 1,
          };
        }
      }

      return Object.values(data);
    },
    countryDetails() {
      return this.countriesData[this.selectedCountry];
    },
    countryDetailsParties() {
      return Object.values(this.countryDetails).map();
    },
    countryDetailsCompare() {
      return this.countriesData[this.selectedCompareCountry];
    },
  },
  methods: {
    onChangeCompareSource({ country_tag, source }) {
      this.selectedCompareCountry = country_tag;
      this.selectedCompareSource = source;
    },
    getCountryRelativePosition(country) {
      const $container = this.$refs.countriesSVG;
      const $country = $container.querySelector(`#${country}`);
      const countryBoundingRect = $country.getBoundingClientRect();
      const containerBoundingRect = $container.getBoundingClientRect();
      return this.getRelativePos(containerBoundingRect, countryBoundingRect);
    },
    onSourceClick(country, source) {
      if (this.selectedSource === source) return;
      this.selectedSource = source;
      if (this.selectedCountry === country) return;
      this.openCountryDetails(country);
    },
    resetForm() {
      this.searchWorkRegistrationsStatus = IDLE;
      this.code = "";
      this.countriesData = {};
      this.onCloseCountryDetailsCard();
      if (this.countries) {
        this.resetCountriesColour(this.countries);
        this.countries = [];
      }
    },
    onCloseCountryDetailsCard() {
      this.showCountryDetails = false;
      this.selectedCountry = null;
      this.selectedSource = null;
      this.$refs.countriesSVG.style.transform = `scale(1) translate(0px, 0px)`;
      this.isMapZoomedIn = false;
    },
    cleanupCode(code) {
      return code.replaceAll("-", "").replaceAll(".", "");
    },
    async onSearch(e) {
      if (!this.code) return;
      this.searchWorkRegistrationsStatus = PENDING;
      const { response, error } = await withAsync(() =>
        searchWorkRegistrations(this.cleanupCode(this.code))
      );

      if (error) {
        console.error(error);
        this.searchWorkRegistrationsStatus = ERROR;
        return;
      }
      this.countries = response.filter(
        ({ country_tag }) => country_tag !== "-"
      );
      this.highlightCountries(this.countries);
      this.searchWorkRegistrationsStatus = SUCCESS;
    },

    onCountryClick(e) {
      const country = e.currentTarget.getAttribute("id");
      this.openCountryDetails(country);
    },
    openCountryDetails(country) {
      this.selectedCountry = country;
      if (!(country in this.countriesData)) {
        this.fetchCountryDetails(this.code, country);
      }
      this.zoomInCountry(country);
      this.showCountryDetails = true;
    },
    onFetchCompareCountryDetails({ country_tag: country, source }) {
      this.selectedCompareCountry = country;
      this.selectedCompareSource = source;
      this.fetchCountryDetails(this.code, country);
    },
    async fetchCountryDetails(code, country) {
      this.fetchCountryDataStatus = PENDING;
      const { response, error } = await withAsync(() =>
        searchWorkRegistrationsByCountry(this.cleanupCode(code), country)
      );

      if (error) {
        this.fetchCountryDataStatus = ERROR;
        return;
      }
      const countryDetails = response.reduce((acc, item) => {
        const {
          source,
          source_name: sourceName,
          country,
          society = "NULL",
          workcode,
          originalTitle: title,
          iswc,
          createdDate,
          lastModifiedDate,
        } = item;
        const societyName = society ? society : "-";
        let key = `${source}-${workcode}`;
        if (!(key in acc)) {
          acc[key] = {
            source,
            sourceName,
            country,
            workcode,
            title,
            societyName,
            createdDate: new Intl.DateTimeFormat("en-US").format(
              new Date(createdDate)
            ),
            lastModifiedDate: new Intl.DateTimeFormat("en-US").format(
              new Date(lastModifiedDate)
            ),
            iswc: iswc,
            items: [],
          };
        }

        acc[key].items.push({
          ...item,
          createdDate: new Intl.DateTimeFormat("en-US").format(
            new Date(createdDate)
          ),
          lastModifiedDate: new Intl.DateTimeFormat("en-US").format(
            new Date(lastModifiedDate)
          ),
        });
        return acc;
      }, {});
      this.$set(this.countriesData, country, countryDetails);
      this.fetchCountryDataStatus = SUCCESS;
    },
    resetCountriesColour(countries) {
      this.changeCountriesColour(countries, "#EDEEF3");
    },
    highlightCountries(countries) {
      this.changeCountriesColour(countries, "#B5B9CC");
    },
    changeCountriesColour(countries, colour) {
      for (const { country_tag: country } of countries) {
        try {
          const $countryEl = this.$refs.countriesSVG.querySelector(
            `#${country}`
          );
          if (!$countryEl) continue;
          const useEl = $countryEl.childNodes[1];
          useEl.setAttribute("fill", colour);
          $countryEl.addEventListener("click", this.onCountryClick);
        } catch (error) {
          console.error(error);
        }
      }
    },
    getElCenterPosition(boundingRect) {
      const { left, top, width, height } = boundingRect;
      return {
        centerX: left + width / 2,
        centerY: top + height / 2,
      };
    },
    getRelativePos(parentPos, childPos) {
      let relativePos = {};
      (relativePos.top = childPos.top - parentPos.top),
        (relativePos.right = childPos.right - parentPos.right),
        (relativePos.bottom = childPos.bottom - parentPos.bottom),
        (relativePos.left = childPos.left - parentPos.left);
      return relativePos;
    },
    async zoomInCountry(country) {
      const $container = this.$refs.countriesSVG;
      const $country = this.$refs.countriesSVG.querySelector(`#${country}`);
      if (this.isMapZoomedIn) {
        let transformPromise = new Promise((resolve) => {
          const onTransitionEnd = () => {
            $container.removeEventListener("ontransitionend", onTransitionEnd);
            setTimeout(() => {
              resolve();
            }, 250);
          };
          $container.addEventListener("transitionend", onTransitionEnd);
          $container.style.transform = `scale(1) translate(0px, 0px)`;
        });
        await transformPromise;
      }

      const countryBoundingRect = $country.getBoundingClientRect();
      const containerBoundingRect = $container.getBoundingClientRect();

      const countryCenter = this.getElCenterPosition(countryBoundingRect);

      let startCenter = this.getElCenterPosition(containerBoundingRect);
      let translateX = startCenter.centerX - countryCenter.centerX;
      let translateY = startCenter.centerY - countryCenter.centerY;

      $container.style.transform = `scale(3.5) translate(${
        translateX + 100
      }px, ${translateY + 50}px)`;
      this.isMapZoomedIn = true;
    },
  },
};
</script>

<style lang="scss">
:root {
  --theme__color1: #4ef7cf;
  --theme__color2: #45507f;
  --font1: "Helvetica Neue";
}
body {
  font-size: 16px;
  line-height: 1.2;
  background-color: #fff;
  color: #373a3c;
  overflow-x: hidden;
  word-wrap: break-word;
  word-break: normal;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  font-family: var(--font1);
}
a,
a:active,
a:focus,
a:active,
a:hover {
  text-decoration: none !important;
  color: inherit;
}
input {
  -ms-box-sizing: border-box;
  box-sizing: border-box;
}
a:hover,
a:focus,
input:focus,
input:hover,
select:focus,
select:hover,
select:active,
textarea:focus,
textarea:hover,
button:focus {
  outline: none;
}
::placeholder {
  opacity: 1;
}
:focus::placeholder {
  opacity: 0;
}
table {
  border-collapse: separate;
  border-spacing: 0;
  width: 100%;
}
img {
  -ms-interpolation-mode: bicubic;
  border: 0;
  height: auto;
  max-width: 100%;
  vertical-align: middle;
}
iframe {
  width: 100%;
}
h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-weight: 700;
}
ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
p {
  font-size: 18px;
  line-height: 1.2;
  margin-bottom: 15px;
}
p:last-child {
  margin: 0;
}
</style>

<style lang="scss" scoped>
::v-deep {
  #worldRightsholderIo {
    will-change: transform;
  }
}

/*-----------------------------------=========Main-Style-Sheet=========----------------------------------------*/

// @font-face {
//   font-family: "Helvetica Neue";
//   src: url("../../../../assets/fonts/HelveticaNeue-CondensedBlack.woff") format("woff"),
//     url("../../../../assets/fonts/HelveticaNeue-CondensedBlack.ttf") format("truetype");
//   font-weight: 900;
//   font-style: normal;
//   font-display: swap;
// }

// @font-face {
//   font-family: "Helvetica Neue";
//   src: url("../../../../assets/fonts/HelveticaNeue-CondensedBold.woff") format("woff"),
//     url("../../../../assets/fonts/HelveticaNeue-CondensedBold.ttf") format("truetype");
//   font-weight: bold;
//   font-style: normal;
//   font-display: swap;
// }

@font-face {
  font-family: "Helvetica Neue";
  src: url("../../../../assets/fonts/helvetica-neue-light.woff") format("woff"),
    url("../../../../assets/fonts/helvetica-neue-light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

// @font-face {
//   font-family: "Helvetica Neue";
//   src: url("../../../../assets/fonts/HelveticaNeue-Italic.woff") format("woff"),
//     url("../../../../assets/fonts/HelveticaNeue-Italic.ttf") format("truetype");
//   font-weight: normal;
//   font-style: italic;
//   font-display: swap;
// }

@font-face {
  font-family: "Helvetica Neue";
  src: url("../../../../assets/fonts/helvetica-neue-light-italic.woff")
      format("woff"),
    url("../../../../assets/fonts/helvetica-neue-light-italic.ttf")
      format("truetype");
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Helvetica Neue";
  src: url("../../../../assets/fonts/helvetica-neue-medium.woff") format("woff"),
    url("../../../../assets/fonts/helvetica-neue-medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

// @font-face {
//   font-family: "Helvetica Neue";
//   src: url("../../../../assets/fonts/HelveticaNeue-UltraLight.woff") format("woff"),
//     url("../../../../assets/fonts/HelveticaNeue-UltraLight.ttf") format("truetype");
//   font-weight: 100;
//   font-style: normal;
//   font-display: swap;
// }

// @font-face {
//   font-family: "Helvetica Neue";
//   src: url("../../../../assets/fonts/HelveticaNeue-UltraLightItalic.woff") format("woff"),
//     url("../../../../assets/fonts/HelveticaNeue-UltraLightItalic.ttf") format("truetype");
//   font-weight: 100;
//   font-style: italic;
//   font-display: swap;
// }

@font-face {
  font-family: "Helvetica Neue";
  src: url("../../../../assets/fonts/helvetica-neue-regular.woff")
      format("woff"),
    url("../../../../assets/fonts/helvetica-neue-regular.ttf")
      format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Helvetica Neue";
  src: url("../../../../assets/fonts/helvetica-neue-bold.woff") format("woff"),
    url("../../../../assets/fonts/helvetica-neue-bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Helvetica Neue";
  src: url("../../../../assets/fonts/helvetica-neue-bold-italic.woff")
      format("woff"),
    url("../../../../assets/fonts/helvetica-neue-bold-italic.ttf")
      format("truetype");
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

.main-wrap {
  width: 100%;
  overflow: hidden;
  min-height: 100vh;
}
@media (min-width: 1600px) {
  .container {
    max-width: 1500px;
  }
}
// main {
//   margin-top: 0px;
//   min-height: 100vh;
//   display: flex;
//   flex-direction: column;
// }
// @media (max-width: 575px) {
//   main {
//     margin-top: 0px;
//   }
// }

.main-section {
  padding: 50px 45px;
  display: flex;
  height: 100%;
  align-items: center;
  flex-grow: 1;
  z-index: 2;
  position: relative;
  /* background-position: right top !important;
  background-size: contain !important;
  background-repeat: no-repeat !important; */
}
.main-section-inner {
  display: flex;
  flex-grow: 1;
  width: 100%;
}
.search-type-content {
  max-width: 340px;
  width: 100%;
  margin: 0 90px 0 0;
  flex-shrink: 0;
}
.search-type-content h1 {
  padding: 0 0 20px;
  font-size: 36px;
}
.search-type-content h1 .highlight {
  position: relative;
  z-index: 1;
  display: block;
  width: max-content;
}
.search-type-content h1 .highlight:after {
  content: "";
  height: 10px;
  width: 100%;
  background: var(--theme__color1);
  position: absolute;
  bottom: 0.35rem;
  left: 0;
  z-index: -1;
}
.search-type-content .single-input {
  margin: 70px 0 0;
  width: 100%;
}
.search-type-content .single-input input {
  height: 46px;
  font-size: 18px;
  font-weight: 500;
  border: 1px solid #979797;
  resize: none;
  border-radius: 5px;
  padding: 7px 12px;
  font-weight: 500;
  width: 100%;
}
.search-type-content .single-input label {
  margin: 0 0 10px;
  font-size: 18px;
  font-weight: 700;
}
.search-type-content .single-input .button {
  font-size: 28px;
  height: 45px;
  padding: 0 35px;
  border: none;
  border-radius: 10px;
  font-weight: 500;
  width: 100%;
  color: #fff;
  background: #45507f;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  transition: 0.3s;
}
.search-type-content .single-input .button:hover {
  background: #445499;
}
.search-type-content .single-input.result-box {
  display: flex;
  gap: 18px;
  flex-wrap: wrap;
}
.search-type-content .single-input.result-box .button {
  padding: 0 14px;
  background: #edeef3;
  border: 1px solid var(--theme__color2);
  width: initial;
  font-size: 18px;
  color: #000;
}
.search-type-content .single-input.result-box span {
  font-weight: 500;
}

@media (max-width: 1399px) {
  .main-section .logo-column {
    display: none;
  }

  .main-section .shape1 {
    right: 100px;
  }
}

@media (max-width: 1199px) {
  .search-type-content {
    margin: 0 70px 0 0;
  }

  .card-type-content.full-page .ctc-content ul li {
    width: 50%;
  }
}

@media (max-width: 991px) {
  .main-section-inner {
    flex-direction: column;
    align-items: center;
    gap: 50px;
  }
  .main-section {
    background: #fff !important;
  }
  .search-type-content {
    margin: 0;
  }

  .main-section .shape1 {
    display: none;
  }
}

@media (max-width: 768px) {
  .search-type-content h1 {
    padding: 0 0 15px;
    font-size: 28px;
  }
  .search-type-content h1 .highlight::after {
    height: 9px;
  }
  .main-section {
    padding: 70px 15px;
  }

  .card-type-content.full-page .ctc-content ul li {
    width: 100%;
  }
}

@media (max-width: 575px) {
  .card-type-content {
    box-shadow: none;
  }
}

@media (max-width: 575px) {
  .card-type-content {
    padding: 30px 0;
  }
}

@media (max-width: 390px) {
}
</style>
<style lang="scss" module>
.worldSearch {
  max-width: 1280px;
  margin: 0 auto;
}

.logoContainer {
  z-index: 10;
  position: absolute;
  top: 8rem;
  right: 2rem;
  width: 20 rem;
  display: none;
  @media (min-width: 768px) {
    display: block;
  }
}

.detailsCardContainer {
  grid-area: 1 / 2 / 2 / 3;

  &.comparing {
    grid-area: 1 / 1 / 2 / 2;
  }
}

.detailsCardCompareContainer {
  grid-area: 1 / 2 / 2 / 3;
}

.main {
  pointer-events: none;
  display: grid;
  grid-template-columns: 1fr;
  align-items: stretch;
  min-height: 100vh;
  position: relative;

  @media (min-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (min-width: 1280px) {
    grid-template-columns: repeat(3, 1fr);

    &.comparing {
      grid-template-columns: repeat(2, 1fr);
      justify-items: center;
      gap: 2rem;
    }
  }
}

.tagsContainer {
  margin: 2rem 0;
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}

.sourceTagBtn {
  padding: 0.4rem 0.6rem;
  border-radius: 6px;
  border: 1px solid #45507f;
  background: #edeef3;
  cursor: pointer;

  &.multiple {
    border: 2px solid #b91c1c;
    background: #fef2f2;
    color: #b91c1c;
  }
}

.codeInput,
.sourceTagBtn,
.searchWorkRegistrationsBtn {
  pointer-events: all;
}

.countriesSVG {
  transition: 1s all ease-in-out;
  will-change: transform;
  outline: none;
}

.countriesSVGContainer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 1s all ease-in-out;
  will-change: transform;
}
</style>